var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"crud-box list-notifications",attrs:{"elevation":0,"tile":""}},[_c('h1',{staticClass:"mx-4"},[_vm._v("Notifications")]),_c('v-layout',{staticClass:"px-4 pt-4",attrs:{"wrap":""}},[_c('div',{staticStyle:{"overflow":"visible"}}),_c('v-spacer'),_c('v-layout',{staticStyle:{"max-width":"255px"},attrs:{"wrap":""}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"dense":"","label":"Search Message","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch.apply(null, arguments)}},model:{value:(_vm.searchFields.data.value),callback:function ($$v) {_vm.$set(_vm.searchFields.data, "value", $$v)},expression:"searchFields.data.value"}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.doSearch}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1)],1),_c('v-card',{staticClass:"block-tbl mx-4",attrs:{"disabled":_vm.tblLoading,"elevation":1,"outlined":"","tile":""}},[_c('v-data-table',{attrs:{"expanded":_vm.expanded,"headers":_vm.tblHeader,"items":_vm.listItems,"loading":_vm.tblLoading,"loading-text":_vm.tblConfig.text.loading,"options":_vm.tblOptions,"server-items-length":_vm.tblPagination.total,"show-select":_vm.tblConfig.showSelect,"single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.tblOptions=$event},"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.tblConfig.text.noItem)+" ")]},proxy:true},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"notification-message",class:{'message-unread': item.status === 'unread'}},[_vm._v(_vm._s(item.message))])]}},{key:"item.created_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-date"},[_vm._v(_vm._s(item.created_time.split('T')[0]))]),_c('div',{staticClass:"text-time"},[_vm._v(_vm._s(item.created_time.split('T')[1]))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.pickedItems),callback:function ($$v) {_vm.pickedItems=$$v},expression:"pickedItems"}})],1),_c('v-dialog',{attrs:{"width":'60%'},model:{value:(_vm.dialog.detail),callback:function ($$v) {_vm.$set(_vm.dialog, "detail", $$v)},expression:"dialog.detail"}},[_c('DetailItem',{attrs:{"onClose":function (){_vm.dialog.detail=false},"selectedItem":_vm.selectedItem}})],1),_c('v-dialog',{attrs:{"width":400},model:{value:(_vm.dialog.delete),callback:function ($$v) {_vm.$set(_vm.dialog, "delete", $$v)},expression:"dialog.delete"}},[_c('DeleteItem',{attrs:{"idsToDelete":_vm.idsToDelete,"onCancel":function (){_vm.dialog.delete=false},"onDeleted":_vm.onDeleted}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }